import { Injectable, NgZone } from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Event as NavigationEvent,
  NavigationStart, ActivatedRoute, NavigationEnd, NavigationError
} from '@angular/router';
import { CommonService } from './common.service';
import { filter } from 'rxjs/internal/operators/filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()

export class AuthGuardService implements CanActivate {
  url: string;
  page_name: any;
  pages: any[];
  public isPublishEnabled: boolean;
  constructor(private router: Router, public commonservice: CommonService, private zone: NgZone, private ngxService: NgxUiLoaderService) {
    console.log('pressed back in add!!!!!');
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if ((event.url.indexOf('login') > -1) || ((event.url === '/') && (event.urlAfterRedirects.indexOf('home') > -1))) {
        this.router.navigate(['/home'], { replaceUrl: true });
        // window.onpopstate = function (e) { window.history.forward(); };
        history.pushState(null, null, window.location.href);
      }
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.pages = JSON.parse(sessionStorage.getItem('Pages'));

    if (this.pages === null) {
      if(state.url.indexOf('/changePassword') > -1 && sessionStorage.getItem('accesstoken')) {
        return true;
      } else {
        this.zone.run(() =>
        this.router.navigate(['/login'])
      );
      return false;
      }

    }
    // if (this.pages.filter(b => b.page_name === 'Home').length === 0) {
    //   if (this.pages.filter(b => b.page_name === this.pages[0].page_name).length === 1) {
    //     this.router.navigate([this.pages[0].page_url]);
    //     this.ngxService.stop();
    //     return false;
    //   }
    // }

    if (this.pages.filter(b => b.page_name === route.routeConfig.data.title).length !== 1) {
      // if (sessionStorage.getItem('RoleName') === 'instant pay') {
      //   if (this.pages.filter(b => b.page_name === 'Instant Pay').length === 1) {
      //     this.router.navigate(['/instant-pay']);
      //     this.ngxService.stop();
      //     return true;
      //   }
      // }
      // if (this.pages.filter(b => b.page_name === this.pages[0].page_name).length === 1) {
      //   this.router.navigate([this.pages[0].page_url]);
      //   this.ngxService.stop();
      //   return true;
      // }

      this.ngxService.stop();
      this.router.navigate(['/unauthorize']);
      if (this.pages.filter(b => b.page_name === this.pages[0].page_name).length === 1) {
        this.router.navigate([this.pages[0].page_url]);
      }
    } else if (sessionStorage.getItem('UserID') != null) {
      this.isPublishEnabled = true;
      return true;
    } else {
      this.zone.run(() =>
        this.router.navigate(['/login'])
      );
      return false;
    }

  }

}
