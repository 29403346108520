import { Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private urls: string[] = [];
  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
  ) {
    router.config.forEach(i => {
      this.getPaths(i);
    })
  }

  private getPaths(route: Route, parent: string = '') {
    if (route.redirectTo) {
      return;
    }
    if (route.children) {
      route.children.forEach(i => {
        this.getPaths(i, parent + route.path);
      });
    }
    else if (route.loadChildren) {
      (<any>this.router).configLoader.load(this.injector, route).subscribe(i => {
        i.routes.forEach(j => {
          this.getPaths(j, parent + route.path)
        });
      });
    }
    else if (route.path != null) {
      this.setPath(route.path, parent);
    }
  }
  private setPath(path, parent) {
    let fullPath: string;
    if (path != null) {
      if (parent) {
        fullPath = `/${parent}`;
      }
      else {
        fullPath = `/${path}`
      }
      const route = fullPath.split('/');
      this.urls.push(route.length > 2 ? ('/' + route[1]): fullPath);
    }
  }
  getUrls = () => this.urls;
}
