import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './layout/pages/services/authguard.service';
// import { ChangepasswordComponent } from './layout/pages/changepassword/changepassword.component';

const routes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule' },
    { path: 'login', loadChildren: './login/login.module#LoginModule'},
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'error', loadChildren: './server-error/server-error.module#ServerErrorModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    { path: 'changePassword/:id', loadChildren :'./changepassword/changepassword.module#ChangepasswordModule' },
    { path: 'privacy-statement/1', loadChildren: './privacy-statement/privacy-statement.module#PrivacystatementModule'},
    { path: 'Terms-of-Use/22', loadChildren: './privacy-statement/privacy-statement.module#PrivacystatementModule'},
    { path: 'caution-notice/27', loadChildren: './privacy-statement/privacy-statement.module#PrivacystatementModule'},
    { path: 'contactus', loadChildren: './contactus/contactus.module#ContactusModule'},
    { path: '**', redirectTo: 'not-found' },   

];

@NgModule({
    imports: [ RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
