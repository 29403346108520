// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /* DEV */
  // apiURL: 'http://10.91.19.181:550/API/',
  // // nhubDevUrl: 'https://vhealthnhubuat.iho.in/api_india_dev/',
  // nhubDevUrl: 'https://vhealthnhubuat.iho.in/aetna_client_api/',
  // cipherText: 'jcsecretkey#123$',

  /* UAT */
  apiURL: 'https://vhealthuatapi.iho.in/API/',
  // nhubDevUrl: 'https://vhealthnhubuat.iho.in/aetna_client_api/',
  nhubDevUrl: 'https://vhealthnhubuat.iho.in/Aetna_DSA_Partner/',
  nhubDevUrlWeb: 'https://vhealthuatapi.iho.in/API/',
  cipherText: 'jcsecretkey#123$',
  websiteURLText: "https://vhealthuat.iho.in/"

  /* LIVE */
  // apiURL: 'https://vhealthprodapi.vhealth.io/API/',
  // // nhubDevUrl: 'https://vhealth.iho.in/aetna_client_api/',
  // nhubDevUrl: 'https://vhealth.iho.in/Aetna_DSA_Partner/',
  // nhubDevUrlWeb: 'https://vhealth.iho.in/API/',
  // cipherText: 'jcsecretkey#123$',
  // apiURL: 'https://vhealthprodapi.vhealth.io/API/',
  // //nhubDevUrl: 'https://vhealthprodapi.vhealth.io/aetna_client_api/',
  // nhubDevUrl: 'https://vhealthprodapi.vhealth.io/Aetna_DSA_Partner/',
  // cipherText: 'jcsecretkey#123$',
  // nhubDevUrlWeb: 'https://vhealthprodapi.vhealth.io/API/',
  // websiteURLText: "https://www.vhealth.io/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
