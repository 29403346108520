import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExcelService } from './layout/pages/services/excel.service';
import { AuthGuardService } from './layout/pages/services/authguard.service';
import { CommonService } from './layout/pages/services/common.service';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule, Router} from '@angular/router';
import { RouteService } from './shared/services/route.service';
import {TooltipModule} from 'ng2-tooltip-directive';
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CarouselModule,
        FormsModule,
        TooltipModule,
        NgbModule.forRoot(), NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RecaptchaModule.forRoot({
            siteKey: '6LeQqL4UAAAAADC-qHAcbPJKgurHjhMQ4Qu01wBc',
        }),
        AppRoutingModule,
        NgxUiLoaderModule
    ],
    declarations: [
        AppComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [AuthGuardService, ExcelService, CommonService, RouteService],
    bootstrap: [AppComponent]
})
export class AppModule { }
