import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';
import { Observable, Subject } from 'rxjs';
import { ExistingCustInfoByLan, OrgNameList } from '../../../shared/models/common.model';

@Injectable()
export class CommonService implements OnInit {
  user =new Subject();

  HomePreviewContent;
  instantThanksData;
  constructor(public http: HttpClient) { this.HomePreviewContent = {}; }

  setHomePreviewContent(val: object) {
    this.HomePreviewContent = val;
  }
  getHomePreviewContent() {
    return this.HomePreviewContent;
  }

  setInstantThanksRequest(val: object) {
    this.instantThanksData = val;
  }
  getInstantThanksRequest() {
    return this.instantThanksData;
  }

  ngOnInit() { }
  // Home Section Starts
  addEditBannerDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateHomeBanner', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getHomeBannerDetails() {
    return this.http.get(environment.apiURL + 'getHomeBannerDetails')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getMemberDetails() {
    return this.http.get(environment.apiURL + 'getMemberDetails')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDSAProducts(data) {
    return this.http.post(environment.nhubDevUrl + 'DSAGetProductMaster',data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  GetNewProductDetailsRenewelRetention(data) {
    return this.http.post(environment.nhubDevUrl + 'GetNewProductDetailsRenewelRetention',data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  GetDSACustomerDetails(data){
    return this.http.post(environment.nhubDevUrl + 'GetDSACustomerDetails',data)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }

  addUpdateMemberDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateMember', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteMemberDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteMember', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getFeatureDetails() {
    return this.http.get(environment.apiURL + 'getAllFeatures')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateFeature', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteFeature', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAllProductsDetails() {
    return this.http.get(environment.apiURL + 'getAllProducts')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  homePreviewDetails() {
    return this.http.post(environment.apiURL + 'previewHomePage', '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProductMasterDetails() {
    return this.http.get(environment.apiURL + 'getProductMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProductSection(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProduct', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteProductDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteHomeProduct?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  PublishHomePage(data) {
    return this.http.post(environment.apiURL + 'publishHomePage', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getLatestNewDetails() {
    return this.http.get(environment.apiURL + 'getLatestNews')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateLatestNewDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateLatestNews', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteLatestNewDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteLatestNews?latest_news_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAllImageSectionLookup() {
    return this.http.get(environment.apiURL + 'getSecondoryProductMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAllImageSectionDetails() {
    return this.http.get(environment.apiURL + 'getAllImagePosts')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateImageSecDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateImagePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteImageSecDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteImagePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Home Section Ends

  // Testimonial Section Starts

  getMemberList() {
    return this.http.get(environment.apiURL + 'Testimonial/getMemberMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getMemberDetailsByID(data) {
    return this.http.post(environment.apiURL + 'Testimonial/getMemberExperienceById?member_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateMemeberDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/AddorUpdateMemberExperience', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getMemberYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/getMemberYoutubePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateMemeberYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/AddorUpdateMemberYoutubePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteMemberYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/DeleteMemberYoutubePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getMemberBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/getMemberBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateMemeberBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/AddOrUpdateMemberBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteMemberBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/DeleteMemberBottomPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishMemberDetails(data) {
    return this.http.post(environment.apiURL + 'Testimonial/publishTestimonial', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  previewMemberDetails(data) {
    return this.http.get(environment.apiURL + 'Testimonial/previewTestimonial?member_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Testimonial Section Ends

  // Product Description Section Starts

  getProDescPreviewDetails(data) {
    return this.http.post(environment.apiURL + 'previewProductDescriptionPage', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProDescBanDetails(data) {
    return this.http.post(environment.apiURL + 'getProductDescriptionBannerbyId', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProDescBanDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductDescriptionBanner', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteProDescBanDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProductDescriptionBanner?product_banner_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProDescTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'getProductDescriptionTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  AddUpdateProDescTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductDescriptionTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteProDescTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProductDescriptionTopPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProDescBenefitDetails(data) {
    return this.http.post(environment.apiURL + 'getProductBenefits', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProDescBenefitDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateBenefit', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteProDescBenefitDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProductDescriptionBenefit', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProDescBottomPostDetails(data) {
    return this.http.post(environment.apiURL + 'getProductDescriptionBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProDescBottomPostDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductDescriptionBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishProDescPage(data) {
    return this.http.post(environment.apiURL + 'publishProductDescription', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // Product Description Section Ends

  // Doctors Section Starts
  getDoctorsBanDetails() {
    return this.http.get(environment.apiURL + 'getDoctorBanners')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateDoctorsBanDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateDoctorBanner', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteDoctorsBanDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteDoctorBanner?doctor_banner_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDoctorsTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'getDoctorTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateDoctorsTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateDoctorTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteDoctorsTopPostDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteDoctorTopPost?page_content_id = ' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDoctorsBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'getDoctorBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateDoctorsBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateDoctorBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteDoctorsBotPostDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteDoctorBottomPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDoctorsDetails() {
    return this.http.get(environment.apiURL + 'getDoctors')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateDoctorsDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateDoctors', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteDoctorsDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteDoctorDetail?doctor_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDoctorsYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'getDoctorYoutubePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateDoctorsYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateDoctorYoutubePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteDoctorsYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteDoctorYoutubePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishDoctorsPage(data) {
    return this.http.post(environment.apiURL + 'publishDoctor', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getDoctorsPageDetails(data) {
    return this.http.post(environment.apiURL + 'previewDoctorPage', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Doctors Section Ends

  // Product Detail Section Starts

  addUpdateCRMProductDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateCRMProduct', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteManageDetails(data) {
    return this.http.post(environment.apiURL + '/DeleteCRMProduct?productId=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDBannerDetails(data) {
    return this.http.post(environment.apiURL + 'getProductDetailBannerbyId', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDBannerDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductDetailBanner', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDBannerDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProductDetailBanner?product_banner_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDKeyFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'getProdDetailKeyFeatures', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDKeyFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProdDetailKeyFeature', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDKeyFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProdDetailKeyFeature?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDPostDetails(data) {
    return this.http.post(environment.apiURL + 'getProdDetailPostSections', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDPostDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProdDetailPostSection', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDPostDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProdDetailPostSection?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'getProdDetailFeaturePosts', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProdDetailFeaturePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDFeatureDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProdDetailFeaturePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'getProdDetailYoutubePosts', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProdDetailYoutubePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDYouTubeDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProdDetailYoutubePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDOtherMemDetails(data) {
    return this.http.post(environment.apiURL + 'getProdDetailOtherMemberships', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDOtherMemDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProdDetailOtherMembership', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDOtherMemDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProdDetailOtherMembership?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDPreviewDetails(data) {
    return this.http.post(environment.apiURL + 'previewProductDetailPage', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishPDPage(data) {
    return this.http.post(environment.apiURL + 'publishProductDetail', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getProPlanDetails(data) {
    return this.http.get(environment.apiURL + 'getProductPlanById?product_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProPlanDetails(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductPlan', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteProPlanDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteProductPlan?product_plan_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // Product Detail Section Ends

  // Product Plan Starts
  getProductPlanList(data) {
    return this.http.get(environment.apiURL + 'getProductPlanVariants?product_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateProductPlan(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateProductPlanVarient', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Member Description
  getMembershipFeature(data) {
    return this.http.get(environment.apiURL + 'getProductMembershipFeature?variant_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  AddorUpdateProductMembershipFeature(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateProductMembershipFeature', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // Product Plan Ends

  // Footer Section starts
  getFooterList() {
    return this.http.get(environment.apiURL + '/getFooterMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateFooterList(data) {
    return this.http.post(environment.apiURL + '/AddorUpdateFooter', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteFooterDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteFooter?footer_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPDFList() {
    return this.http.get(environment.apiURL + 'PDF/GetPDFdetails')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdatePDFDetails(data) {
    return this.http.post(environment.apiURL + '/PDF/AddorUpdatePDFdetail', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deletePDFDetails(data) {
    return this.http.post(environment.apiURL + '/PDF/DeletePDFdetail?pdf_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getFooterDescList() {
    return this.http.get(environment.apiURL + 'getAllFooterDescription')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateFooterDesc(data) {
    return this.http.post(environment.apiURL + '/AddorUpdateFooterDescription', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteFooterDesc(data) {
    return this.http.post(environment.apiURL + 'DeleteFooterDescription?footer_description_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getFooterSocialMedia() {
    return this.http.get(environment.apiURL + '/getSocialMediaList')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateFooterSocialMedia(data) {
    return this.http.post(environment.apiURL + '/AddorUpdateSocialMedia', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteFooterSocialMedia(data) {
    return this.http.post(environment.apiURL + 'DeleteSocialMedia?social_media_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Footer Section ends

  // Contact Us Starts
  getContactUsDescList() {
    return this.http.get(environment.apiURL + 'getContactUs')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateContactUsDesc(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateContactUs', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteContactUs(data) {
    return this.http.post(environment.apiURL + 'DeleteContactUs?footer_description_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getContMemberDetails() {
    return this.http.get(environment.apiURL + 'getComplaintForm')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateContMemberDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateComplaint', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteContMemberDetails(data) {
    return this.http.post(environment.apiURL + 'DeleteComplaint?complaint_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishContactUs(data) {
    return this.http.post(environment.apiURL + 'publishContactUs', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishContactMember(data) {
    return this.http.post(environment.apiURL + 'publishComplaint', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getContactUsReport(data) {
    return this.http.post(environment.apiURL + 'GetCompliant', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // Contact Us Ends

  // About us Starts

  getAboutUsBannerDetails() {
    return this.http.get(environment.apiURL + 'getAboutUsBanners')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateAboutUsBanner(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateAboutUsBannerDetail', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteAboutUsBanner(data) {
    return this.http.post(environment.apiURL + 'DeleteAboutUsBanner?about_banner_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAboutUsTopPost(data) {
    return this.http.post(environment.apiURL + 'getAboutUsTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateAboutUsTopPost(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateAboutUsTopPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteAboutUsTopPost(data) {
    return this.http.post(environment.apiURL + 'DeleteAboutUsTopPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAboutUsMiddlePost(data) {
    return this.http.post(environment.apiURL + 'getAboutUsMiddlePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateAboutUsMiddlePost(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateAboutUsMiddlePost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteAboutUsMiddlePost(data) {
    return this.http.post(environment.apiURL + 'DeleteAboutUsMiddlePost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAboutUsBottomPost(data) {
    return this.http.post(environment.apiURL + 'getAboutUsBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateAboutUsBottomPost(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateAboutUsBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteAboutUsBottomPost(data) {
    return this.http.post(environment.apiURL + 'DeleteAboutUsBottomPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishAboutUsPage(data) {
    return this.http.post(environment.apiURL + 'publishAboutUs', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  previewAboutUsPage(data) {
    return this.http.post(environment.apiURL + 'previewAboutUsPage', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getAboutUsKnowMore(data) {
    return this.http.get(environment.apiURL + 'getAboutUsMiddleDetailPost?page_content_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateAboutUsKnowMore(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateAboutUsMiddleDetailPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteAboutUsKnowMore(data) {
    return this.http.post(environment.apiURL + 'DeleteAboutUsMiddleDetailPost?page_content_detail_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // About us Ends

  // Network List Starts

  getNetworkBannerDetails() {
    return this.http.get(environment.apiURL + 'getNetworkBanners')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateNetworkBannerPost(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateNetworkBanner', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteNetworkBannerPost(data) {
    return this.http.post(environment.apiURL + 'DeleteNetworkBanner?network_banner_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getNetworkBotPost(data) {
    return this.http.post(environment.apiURL + 'getNetworkBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addUpdateNetworkBotPost(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateNetworkBottomPost', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteNetworkBotPost(data) {
    return this.http.post(environment.apiURL + 'DeleteNetworkBottomPost?page_content_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  publishNetworkList(data) {
    return this.http.post(environment.apiURL + 'publishNetwork', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  // Network List Ends

  // User Role Creation Starts
  login(data) {
    return this.http.post(environment.apiURL + 'login', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  emailOtpLogin(data) {
    return this.http.post(environment.apiURL + 'verifyOTP', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getRoleList() {
    return this.http.get(environment.apiURL + 'getRoleMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getInstantPaymentDetailsList(data){
    return this.http.post(environment.apiURL + 'GetMasterDetailInstantpay', data)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }

  getpagebyrole(data) {
    return this.http.get(environment.apiURL + 'getPagesbyRole?role_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  savepagebyrole(data) {
    return this.http.post(environment.apiURL + 'PagePermissionbyRole', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleterole(data) {
    return this.http.post(environment.apiURL + 'DeleteRole', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addeditrole(data) {
    return this.http.post(environment.apiURL + 'AddOrUpdateRole', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getuserlist() {
    return this.http.get(environment.apiURL + 'getUserMasterData')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getPaymentDetailsList(){
    return this.http.get(environment.apiURL + 'GetMasterDetail')
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }

  deleteuser(data) {
    return this.http.post(environment.apiURL + 'DeleteUser?user_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addedituser(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateUser', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // User Role Creation Ends

  /**** Blog **********/
  addUpdateBlog(data) {
    return this.http.post(environment.apiURL + '/BlogCategory/AddOrUpdate', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  deleteBlogCategory(data) {
    return this.http.post(environment.apiURL + '/BlogCategory/Delete?blog_Category_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getBlogList() {
    return this.http.get(environment.apiURL + '/BlogCategory/List')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  getBlogListbyId(categoryId: number) {
    return this.http.get(environment.apiURL + '/Blogs/getListByCategoryId?category_id=' + categoryId)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  addUpdateBlogDesc(data) {
    return this.http.post(environment.apiURL + '/Blogs/AddOrUpdate', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  deleteBlogDesc(data) {
    return this.http.post(environment.apiURL + '/Blogs/Delete?blog_id=' + data, '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  /**** End Blog **********/

  /**** Auth Token from nHub **********/
  getAuthToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        'clientId': 'FDWhv53XWJXBVUCJGZG3DQ==',
        'secret': 'n3aLfxWtj9cvOTTJuRRSwNjMMF5GTTrbacfZt8l2x4o='
      })
    };

    return this.http.get(environment.nhubDevUrl + 'Identity', httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  /**** End Auth Token **********/

  /**** Manage products **********/
  getCRMProducts() {
    return this.http.post(environment.nhubDevUrl + 'GetCRMProductMaster', '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  getTempCRMProducts() {
    return this.http.post(environment.apiURL + 'GetTempCRMProductMaster', '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  getAllManageProductsDetails() {
    return this.http.get(environment.apiURL + 'GetAllManageProducts')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  /**** End Auth Token **********/


  /* InstantPay */
  getAllInstantPayPlanDetails(path: any): Observable<any> {
    // return this.http.get(`http://www.mocky.io/v2/${path}`);
    return this.http.get(`${environment.apiURL}/${path}`);

  }

  getAllPartnerInstantPayPlanDetails(path: any, partner: any): Observable<any> {
    const partner1 = sessionStorage.getItem('PartnerName');
    const partner2 = partner1.replace(/\s/g, '{-}');
    // console.log("PartnerName222",partner2);
    return this.http.get(`${environment.apiURL}/${path}?${partner}=${partner2}`);
  }

  addCutomerDetails(data) {
    return this.http.post(environment.nhubDevUrl + 'InsertCustomer', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getTataMotorProduct(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };
    return this.http.post(environment.apiURL + 'getTataMotorProduct', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addInstantPayCutomerDetails(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'DSACustomerCreate', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  SaveFinalCheckoutRenewelRetention(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'SaveFinalCheckoutRenewelRetention', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getContactUsDetails() {
    return this.http.post(environment.apiURL + '/site/getContactUsPageDetails?page_id=5', '')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getFooterDesc(data) {
    return this.http.get(environment.apiURL + 'getFooterDescriptionById?footer_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addContactUsDetails(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateVHMember', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  addContactUsFormDetails(data) {
    return this.http.post(environment.apiURL + 'InsertComplaint', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  ValidateEmployeeNumber(data) {
    return this.http.post(environment.apiURL + 'ValidateEmployeeNumber', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  GetPincodeDetails(data) {
    return this.http.post(environment.nhubDevUrl + 'GetPincodeDetails', data)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }

  GetStateCityDetails(data) {
    return this.http.post(environment.nhubDevUrl + 'GetStateCityDetails', data)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }
  DSAGetCustomerDetails(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };
    return this.http.post(environment.nhubDevUrl + 'DSAGetCustomerDetails', data, httpOptions)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }
  GetCustomerDetailsRenewelRetention(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };
    return this.http.post(environment.nhubDevUrl + 'GetCustomerDetailsRenewelRetention', data, httpOptions)
    .map(resdata => {
      return resdata;
    }, error => {
      return null;
    });
  }


  applyCouponCode(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'ValidateCouponCode', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getCustPaymentDetail(data) {
    // return this.http.get(environment.apiURL + 'getCustomerPayementLogDetail?cms_cust_payment_id=' + data)
    return this.http.post(environment.nhubDevUrl + 'GetDSACustomerDetails', data)
   // return this.http.get(environment.apiURL + 'GetDSACustomerDetails?cms_cust_payment_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  getCustPaymentDetail_New(data) {
    // return this.http.get(environment.apiURL + 'getCustomerPayementLogDetail?cms_cust_payment_id=' + data)
    return this.http.post(environment.nhubDevUrl + 'GetUserAndProductDetailRenewelRetention', data)
   // return this.http.get(environment.apiURL + 'GetDSACustomerDetails?cms_cust_payment_id=' + data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  AddCustPaymentDetail(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateCustomerPayementLogDetail', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  ValidateCityPincode(data) {
    return this.http.post(environment.nhubDevUrl + 'CityPincodeValidation', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  /* InstantPay */


  // list coupon
  GetCoupenGeneration(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'GetMasterDetailsForCoupenGeneration', data, httpOptions)
      .map(resdata => {
        console.log(resdata);
        return resdata;
      }, error => {
        return null;
      });
  }

  // generate coupon
  GenerateCoupen(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'GenerateCoupen', data, httpOptions)
      .map(resdata => {
        console.log(resdata);
        return resdata;
      }, error => {
        return null;
      });
  }

  getCityMasterData(path: any): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`);
  }

  getStateMasterData(path: any): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`);
  }

  // Report Starts
  getPaymentTransactionReport() {
    return this.http.get(environment.apiURL + 'GetPaymentDetailResponse')
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  downloadReport(data) {
    return this.http.post(environment.apiURL + 'GetPaymentLog', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  paymentLogDownload(data) {
    return this.http.post(environment.apiURL + 'GetPaymentLogDownload', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  downloadInstantReport(data) {
    return this.http.post(environment.apiURL + 'GetInstantPaymentLog', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  downloadUserInstantReport(data) {
    return this.http.post(environment.apiURL + 'GetUserInstantPaymentLog', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  downloadNetworkReport(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'getCustomerReport', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  getCustomerDetailsbyLan(LoanAccountNo: string) {
    const payload = {LoanAccountNo}
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken') || ''
      })
    };

    return this.http.post<ExistingCustInfoByLan>(environment.nhubDevUrl + 'GetCustomerDetailsbyLan', payload, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  downloadCallusReport(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };

    return this.http.post(environment.nhubDevUrl + 'getCustomerReport', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  validateCityPincodeWithState(data) {
    return this.http.post(environment.nhubDevUrl + 'CITYPINCODEVALIDATION', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  membershipkeyReport(data) {
    return this.http.post(environment.apiURL + 'MembershipkeyReport', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  membershipkeyReportDownload(data) {
    return this.http.post(environment.apiURL + 'MembershipkeyReport_Download', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  // Report Ends

  GetEmailConfig(data) {
    return this.http.post(environment.apiURL + 'getEmailConfig', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  AddorUpdateEmailConfig(data) {
    return this.http.post(environment.apiURL + 'AddorUpdateEmailConfig', data)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  UpdateComplaintReport(data) {
    return this.http.post(environment.apiURL +'UpdateComplaintReport',data)
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }
  GetABFLOrgNameMasterDetail() {
    return this.http.get<OrgNameList>(environment.apiURL +'GetOrgNameMasterDetail')
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }

  sendOtpForgot(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken'),
        'charset':'utf-8'
      })
    };
    return this.http.post(environment.nhubDevUrl + 'sendOTP', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  verifyOtp(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken'),
        'charset':'utf-8'
      })
    };
    return this.http.post(environment.nhubDevUrl + 'static/verifyCustomerRegistrationOTPWebsite', data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }

  reSendOtp(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken'),
        'charset':'utf-8'
      })
    };
    return this.http.get(environment.nhubDevUrl + 'FindOTPID?PhoneNo=' + data, httpOptions)
      .map(resdata => {
        return resdata;
      }, error => {
        return null;
      });
  }
  getUserMasterDetails(data) {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'clientId': 'X5/W/dTN3vKfKM8c+uCadg==',
      'secret': 'ljzlSpbPSNVbtclSvGEPbi0rBCUznDSkIuVoBl39WHk=',
      'Authorization': sessionStorage.getItem('accesstoken')
    })
   }
    return this.http.post(environment.nhubDevUrlWeb +'getUserMasterDetails',data,httpOptions)
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }

  SaveUserDetail(data) {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'clientId': 'X5/W/dTN3vKfKM8c+uCadg==',
      'secret': 'ljzlSpbPSNVbtclSvGEPbi0rBCUznDSkIuVoBl39WHk=',
      'Authorization': sessionStorage.getItem('accesstoken')
    })
   }
    return this.http.post(environment.nhubDevUrlWeb +'SaveUserDetail',data,httpOptions)
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }


  sentOtp(data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };
    return this.http.post(environment.nhubDevUrl +'WebsiteSendOTP',data,httpOptions)
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }
  changePassowrd(data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accesstoken')
      })
    };
    return this.http.post(environment.nhubDevUrl +'WebsiteForgetPassword',data,httpOptions)
    .map(resData=>{
      return resData;
    },error =>{
      return null ;
    })
  }
}
